import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import AddService from './AddService';
import './AdminDashboard.css';
import { db } from '../../firebase';
import { FaSearch } from 'react-icons/fa';
import AddToInstagram from './AddToInstagram';
import Signup from '../auth/Signup';

const AdminDashboard = () => {
  const [services, setServices] = useState([]);
  const [instagramPosts, setInstagramPosts] = useState([]); 
  const [showAddService, setShowAddService] = useState(false);
  const [showAddInstagram, setShowAddInstagram] = useState(false); 
  const [editIndex, setEditIndex] = useState(null);
  const [editService, setEditService] = useState(null);
  const [editInstagramPost, setEditInstagramPost] = useState(null); 
  const [searchQuery, setSearchQuery] = useState('');
  const [notification, setNotification] = useState(null);

  const servicesCollection = collection(db, "services");
  const instagramCollection = collection(db, "instagram");

  // Fetch services
  useEffect(() => {
    const fetchServices = async () => {
      const data = await getDocs(servicesCollection);
      setServices(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    const fetchInstagramPosts = async () => {
      const data = await getDocs(instagramCollection);
      setInstagramPosts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    fetchServices();
    fetchInstagramPosts();
  }, []);

  // Handle Service delete
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      await deleteDoc(doc(db, "services", id));
      setServices(services.filter((service) => service.id !== id));
      setNotification('Service deleted successfully!');
      setTimeout(() => setNotification(null), 3000);
    }
  };

  // Handle Service edit
  const handleEdit = (index) => {
    setEditIndex(index);
    setEditService(services[index]);
    setShowAddService(true);
  };

  // Handle Instagram post delete
  const handleDeleteInstagram = async (id) => {
    if (window.confirm('Are you sure you want to delete this Instagram post?')) {
      await deleteDoc(doc(db, "instagram", id));
      setInstagramPosts(instagramPosts.filter((post) => post.id !== id));
      setNotification('Instagram post deleted successfully!');
      setTimeout(() => setNotification(null), 3000);
    }
  };

  // Handle Instagram post edit
  const handleEditInstagram = (index) => {
    setEditIndex(index);
    setEditInstagramPost(instagramPosts[index]);
    setShowAddInstagram(true);
  };

  // Save Instagram post
  const handleSaveInstagram = async (updatedPost) => {
    if (editIndex !== null) {
      // Update Instagram post
      const postDoc = doc(db, "instagram", instagramPosts[editIndex].id);
      await updateDoc(postDoc, updatedPost);
      setInstagramPosts((prev) => {
        const newPosts = [...prev];
        newPosts[editIndex] = { ...updatedPost, id: instagramPosts[editIndex].id };
        return newPosts;
      });
    } else {
      // Add new Instagram post
      const docRef = await addDoc(instagramCollection, updatedPost);
      setInstagramPosts([...instagramPosts, { ...updatedPost, id: docRef.id }]);
    }
    setNotification(editIndex !== null ? 'Instagram post updated successfully!' : 'Instagram post added successfully!');
    setTimeout(() => setNotification(null), 3000);
    setEditIndex(null);
    setEditInstagramPost(null);
    setShowAddInstagram(false);
  };

  // Save Service
  const handleSaveService = async (updatedService) => {
    if (editIndex !== null) {
      // Update existing service
      const serviceDoc = doc(db, "services", services[editIndex].id);
      await updateDoc(serviceDoc, updatedService);
      setServices((prev) => {
        const newServices = [...prev];
        newServices[editIndex] = { ...updatedService, id: services[editIndex].id };
        return newServices;
      });
    } else {
      // Add new service
      const docRef = await addDoc(servicesCollection, updatedService);
      setServices([...services, { ...updatedService, id: docRef.id }]);
    }
    setNotification(editIndex !== null ? 'Service updated successfully!' : 'Service added successfully!');
    setTimeout(() => setNotification(null), 3000);
    setEditIndex(null);
    setEditService(null);
    setShowAddService(false);
  };

  // Filter services based on search query
  const filteredServices = services.filter((service) =>
    service.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="admin-dashboard">
      {notification && <div className="notification">{notification}</div>}

      {/* Add Instagram post form */}
      {showAddInstagram && (
        <AddToInstagram
          service={editInstagramPost}
          onSave={handleSaveInstagram}
          onCancel={() => {
            setEditIndex(null);
            setEditInstagramPost(null);
            setShowAddInstagram(false);
          }}
          isInstagram={true} // Pass a flag to indicate it's for Instagram
        />
      )}

      {/* Add Service form */}
      {showAddService && (
        <AddService
          service={editService}
          onSave={handleSaveService}
          onCancel={() => {
            setEditIndex(null);
            setEditService(null);
            setShowAddService(false);
          }}
          isInstagram={false} // Indicate that this is for Service
        />
      )}

      <div className="admin-dashboard-header">
        <h2>Admin Dashboard</h2>
        <div className="admin-dashboard-controls">
          <button
            onClick={() => {
              setShowAddService(!showAddService);
              setEditService(null);
              setEditIndex(null);
            }}
            className="toggle-add"
          >
            {showAddService ? 'Close Add Service' : 'Add Service'}
          </button>
          <button
            onClick={() => {
              setShowAddInstagram(!showAddInstagram);
              setEditInstagramPost(null);
              setEditIndex(null);
            }}
            className="toggle-add"
          >
            {showAddInstagram ? 'Close Add Instagram' : 'Add Instagram Post'}
          </button>

          <div className="search-bar">
          <input
            type="text"
            placeholder="Search services..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-bar"
          />
          <FaSearch className="search-bar-icon"/>
          </div>
          
        </div>
      </div>

       {/* Service List */}
       <div className="admin-service-list">
        {filteredServices.length > 0 ? (
          filteredServices.map((service, index) => (
            <div key={service.id} className=" f-s admin-service-item">
              <div className="service-details f-s">

                <div className='f-s'>
                  <img src={service.primaryImage} alt={service.name} className='service-details-image' />
                  <h4>{service.name}</h4>
                </div>

                <div className='f-s '>
                 <p><strong>Location:</strong> {service.location}</p>
                 <p><strong>Price:</strong> ${service.price}</p>
                 <p><strong>Status:</strong> {service.availability}</p>
                </div>
               
              </div>
              <div className="service-actions">
                <button onClick={() => handleEdit(index)} className="edit-btn">Edit</button>
                <button onClick={() => handleDelete(service.id)} className="delete-btn">Delete</button>
              </div>
            </div>
          ))
        ) : (
          <p className="no-services">No services found.</p>
        )}
      </div>

      {/* Instagram Posts List */}
      <div className="admin-service-list">
        {instagramPosts.length > 0 ? (
          instagramPosts.map((post, index) => (
            <div key={post.id} className="admin-service-item">
              <div className='f-s'>
                  <img src={post.image} alt={post.image} className='service-details-image' />
                  <h4>{post.link}</h4>
                </div>
              
              <div className="instagram-actions">
                <a href={post.link} target="_blank" rel="noopener noreferrer"  className='view-post'>View Post</a>
                <button onClick={() => handleEditInstagram(index)} className="edit-btn">Edit</button>
                <button onClick={() => handleDeleteInstagram(post.id)} className="delete-btn">Delete</button>
              </div>
            </div>
          ))
        ) : (
          <p className="no-instagram">No Instagram posts found.</p>
        )}
      </div>

     
      <Signup/>
    </div>
  );
};

export default AdminDashboard;
